import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { animated as a, useTrail, useSpring, useChain } from "react-spring"
import { Location } from "@reach/router"

import ShareButton from "./ShareButton"
import { ShareIcon } from "../../assets/icons"
import { reverse } from "../../utils/helper"

const config = { mass: 5, tension: 3000, friction: 150 }
const items = ["facebook", "twitter", "linkedIn", "reddit", "whatsapp", "email"]

// TODO add social media share to mobile

const Social = ({ color, location }) => {
  const [show, set] = useState(false)
  const [scrolled, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrolled])

  function handleScroll() {
    const scrollPoint = 500

    if (!scrolled && window.pageYOffset > scrollPoint) {
      return setScroll(true)
    }

    if (scrolled && window.pageYOffset < scrollPoint) {
      return setScroll(false)
    }

    return
  }

  const trailRef = useRef()
  const trail = useTrail(items.length, {
    config,
    opacity: show ? 1 : 0,
    x: show ? 0 : 30,
    from: { opacity: 0, x: 20 },
    ref: trailRef,
  })

  const activeRef = useRef()
  const activeProps = useSpring({
    background: show ? `#f5f5f5` : color,
    borderColor: show ? `rgba(189, 187, 187, 0.38)` : `rgba(0, 0, 0, 0)`,
    borderRadius: show ? "0" : "3px",
    width: show ? `55px` : `70px`,
    ref: activeRef,
  })

  useChain(show ? [activeRef, trailRef] : [trailRef, activeRef], [0, 0])

  const revealProps = useSpring({
    to: reverse(scrolled, [
      { visibility: scrolled ? "visible" : "hidden" },
      {
        opacity: scrolled ? 1 : 0,
        transform: scrolled ? `translateY(0)` : `translateY(-100px)`,
      },
    ]),
    from: { opacity: 0, visibility: "hidden", transform: `translateY(0)` },
  })

  return (
    <ShareWrapper style={revealProps}>
      <IconList
        style={{
          visibility: show ? "visible" : "hidden",
          transition: "visibility 0.5s",
        }}
      >
        {trail.map(({ x, ...rest }, index) => (
          <a.li
            key={items[index]}
            style={{
              ...rest,
              transform: x.interpolate(x => `translate3d(${x}px,0,0)`),
            }}
          >
            <Location>
              {({ location }) => (
                <ShareButton url={location.href} type={items[index]} />
              )}
            </Location>
          </a.li>
        ))}
      </IconList>
      <ToggleSocial
        color={color}
        active={`${show}`}
        aria-label="Share on Social Media"
        className="naked-btn"
        onClick={() => set(!show)}
        style={activeProps}
      >
        <ShareIcon />
      </ToggleSocial>
    </ShareWrapper>
  )
}

const ShareWrapper = styled(a.div)`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1080px) {
    display: none;
  }
`
const ToggleSocial = styled(a.button)`
  background: #fbfbfb;
  height: 55px;
  justify-content: center;
  margin-bottom: 10px;
  border: 1px solid;
  margin: 0;

  svg {
    height: 30px;
    fill: ${({ color, active }) => (active === "true" ? color : "#fff")};
  }
`
const IconList = styled(a.ul)`
  list-style-type: none;
  margin-bottom: 10px;

  li {
    margin-bottom: 7px;
  }

  svg {
    height: 55px;
  }

  .social-icon--reddit rect {
    fill: #f54300;
  }
`

Social.propTypes = {
  color: PropTypes.string,
  location: PropTypes.string,
}

Social.defaultProps = {
  color: ``,
  location: ``,
}

export default Social

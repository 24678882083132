import React, { useContext } from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

import { UIContext } from "../../context/UIContext"

const ShareButton = ({ type, url }) => {
  const { state } = useContext(UIContext)
  const shareTitle = `Learn Essential ${state.language.name} for Travel`

  switch (type) {
    case "facebook":
      return (
        <FacebookShareButton url={url}>
          <FacebookIcon size={55} />
        </FacebookShareButton>
      )
    case "twitter":
      return (
        <TwitterShareButton url={url} via="mkdarshay" title={shareTitle}>
          <TwitterIcon size={55} />
        </TwitterShareButton>
      )
    case "linkedIn":
      return (
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={55} />
        </LinkedinShareButton>
      )
    case "reddit":
      return (
        <RedditShareButton url={url} title={shareTitle}>
          <RedditIcon size={55} />
        </RedditShareButton>
      )
    case "whatsapp":
      return (
        <WhatsappShareButton url={url} title={shareTitle}>
          <WhatsappIcon size={55} />
        </WhatsappShareButton>
      )
    case "email":
      return (
        <EmailShareButton url={url} subject={shareTitle}>
          <EmailIcon size={55} />
        </EmailShareButton>
      )
    default:
      return null
  }
}

ShareButton.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
}

ShareButton.defaultProps = {
  type: ``,
  url: ``,
}

export default ShareButton

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Trans } from "@lingui/macro"

import Modal from "../Modal"
import {
  spacing,
  radius,
  breakpoints,
  fonts,
  dimensions,
} from "../../utils/styles"
import { updateUI } from "../../context/UIContext"
import {
  FCIcon,
  AudioToggle,
  ClockIcon,
  CheckMark,
  InfoIcon,
} from "../../assets/icons"

const checkForMobile = () => {
  if (typeof window !== `undefined`) {
    return window.innerWidth < breakpoints.tablet ? true : false
  }
}

const LangTools = React.memo(({ audioLabel, audioView, meta }) => {
  const [mobile, setMobile] = useState(checkForMobile)
  const [showInfo, setInfo] = useState(false)
  const { toggleFlashCards } = updateUI()

  useEffect(() => {
    window.addEventListener(`resize`, handleResize)
    return () => window.removeEventListener(`resize`, handleResize)
  }, [])

  const handleResize = e => {
    if (mobile !== checkForMobile) {
      setMobile(checkForMobile)
    }
  }

  return (
    <ToolContainer>
      <ButtonWrapper>
        <ToolButton
          onClick={() => toggleFlashCards()}
          color={meta.color}
          active={true}
        >
          <FCIcon />
          <span className="button-text">
            <Trans>Flashcards</Trans>
          </span>
        </ToolButton>

        <ToolButton
          onClick={meta.audio ? audioView : null}
          color={meta.color}
          active={meta.audio ? true : false}
        >
          <AudioToggle active={audioLabel} />
          <span className="button-text">
            {meta.audio ? (
              <Trans id={audioLabel ? "Hide Audio" : "Show Audio"} />
            ) : (
              <Trans>Unavailable</Trans>
            )}
          </span>
        </ToolButton>
      </ButtonWrapper>
      {meta.sgUrl ? (
        <SGWrapper color={meta.color}>
          <SGHeader>
            <h4>
              <Trans>Study Guide</Trans>
            </h4>
            <InfoIcon setInfo={setInfo} showInfo={showInfo} />
            <Modal
              padding="25px"
              position={{ top: 35 }}
              toggle={showInfo}
              classes="info-modal"
            >
              <Trans
                id={
                  meta.dev
                    ? `This language has a study guide coming soon. Subscribe for updates 🙏.`
                    : `This language has additional vocabulary, phrases, and verb conjugations.`
                }
              />
            </Modal>
          </SGHeader>
          <a href={meta.sgUrl} target="_blank" rel="noopener noreferrer">
            {meta.sgUrl && !meta.dev ? <CheckMark /> : <ClockIcon />}
            {mobile ? <Trans>Study Guide</Trans> : meta.sgUrl}
          </a>
        </SGWrapper>
      ) : null}
    </ToolContainer>
  )
})

// Styled Components

const ToolContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 20px;
  margin: 30px 0;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0;
    margin: 50px 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
`

const ToolButton = styled.button`
  display: flex;
  justify-content: center;
  margin-right: ${spacing.md}px;
  background: ${({ active }) => (active ? `#fff` : `#f9f9f9`)};
  border: 1px solid #d8d8d8;
  border-radius: ${radius.default}px;
  padding: 12px;
  box-shadow: 4px 3px 3px rgba(146, 146, 146, 0.06);
  transition: all ease 0.3s;

  svg {
    fill: ${({ color, active }) => (active ? color : ` #a2a2a2`)};
    transition: fill ease 0.5s;
  }

  span {
    padding: 0 10px 0 15px;
  }

  .button-text {
    display: none;
  }

  &:hover {
    color: ${({ color }) => color};
    box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.07);
  }

  @media (min-width: ${breakpoints.tablet}px) {
    .button-text {
      display: block;
    }
    padding: 15px;
  }
`

const SGWrapper = styled.div`
  margin: 0;

  a,
  .empty {
    font-family: ${fonts.heading};
    color: #2d2d2d;
    border: 1px solid #dedede;
    background: #fbfbfb;
    padding: 12px 20px 12px 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    box-shadow: 5px 2px 4px 0px rgba(0, 0, 0, 0.03);
  }

  svg {
    height: 23px;
    margin-right: 15px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    a {
      padding: 15px 20px 15px 15px;
    }
  }
`

const SGHeader = styled.div`
  display: none;
  position: relative;
  align-items: center;
  margin-bottom: 15px;

  h4 {
    margin: 0;
    font-size: 23px;
  }

  svg {
    margin-left: 7px;
    fill: #a2a2a2;
    height: 19px;
    cursor: pointer;
  }

  .info-modal {
    font-family: ${fonts.heading};
    line-height: 1.4;
    width: ${dimensions.studyGuideModal}px;
    right: 0px;

    @media (min-width: 960px) {
      left: 0px;
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

LangTools.propTypes = {
  audioView: PropTypes.func,
  audioLabel: PropTypes.bool,
  meta: PropTypes.object,
}

LangTools.defaultProps = {
  audioView: false,
  audioLabel: false,
  meta: {},
}

export default LangTools

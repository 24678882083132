import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import { updateUI } from "../../context/UIContext"
import SEO from "../SEO"
import LangTools from "./LangTools"
import Social from "../Social"
import LangTable from "./LangTable"

const DetailPage = React.memo(({ pageContext, ...props }) => {
  const data = pageContext
  const { loadFlashCards } = updateUI()
  const [audio, toggleAudio] = useState(true)

  useEffect(() => {
    loadFlashCards(data.body)
  }, [])

  const audioView = () => toggleAudio(!audio)

  function formatTables(col) {
    return data.body.map(section => {
      if (section.col === col) {
        return (
          <LangTable
            key={section.category}
            data={section}
            meta={data.meta}
            audio={audio}
          />
        )
      }
      return null
    })
  }

  return (
    <>
      <SEO
        title={`${data.meta.name} Travel Phrases`}
        keywords={[
          data.meta.name,
          `travel`,
          `phrases`,
          `phrasebook`,
          `Language Guide`,
          `language`,
          `flashcards`,
        ]}
      />
      <SiteContent>
        <LangTools audioView={audioView} audioLabel={audio} meta={data.meta} />
        <ColumnWrapper>
          <Column>{formatTables(1)}</Column>
          <Column>{formatTables(2)}</Column>
        </ColumnWrapper>
      </SiteContent>
      <Social color={data.meta.color} />
    </>
  )
})

// Styled Components

const Column = styled.div``

const SiteContent = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 20px 80px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 50px;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export default DetailPage

import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useSpring, useTransition, animated as a } from "react-spring"
import { Trans } from "@lingui/macro"

import { UIContext } from "../../context/UIContext"
import AudioButton from "../AudioButton"
import { LaunchIcon } from "../../assets/icons"
import { radius, fonts, breakpoints } from "../../utils/styles"

const cyrillic = ["Russian", "Ukrainian", "Serbian", "Greek"]

const LangTable = React.memo(({ data, meta, audio }) => {
  const { state } = useContext(UIContext)
  const isCyrillic = cyrillic.includes(meta.name)

  // Animations
  const translate = useSpring({
    transform: `translateX(${audio ? 0 : 40}px)`,
  })

  const transitions = useTransition(audio, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  function renderCell(translation) {
    if (meta.special) {
      return (
        <>
          <Characters cyrillic={isCyrillic}>
            {translation.characters}
          </Characters>
          <Romanization>{translation.romanization}</Romanization>
        </>
      )
    }
    return translation.romanization
  }

  function formatCells(phrases) {
    return phrases.map((p, index) => (
      <tr key={p.base} className="special">
        <td>
          <Trans id={p.base} />
        </td>
        <TDShift style={translate}>{renderCell(p.translation)}</TDShift>
        {meta.audio ? (
          <td>
            {transitions.map(
              ({ item, key, props }) =>
                item && (
                  <AudioDiv key={key} style={props}>
                    <AudioButton
                      index={index}
                      inline={false}
                      string={
                        meta.special
                          ? p.translation.characters
                          : p.translation.romanization
                      }
                      language={meta.name}
                    />
                  </AudioDiv>
                )
            )}
          </td>
        ) : null}
      </tr>
    ))
  }

  function renderSGLink(path, url) {
    if (path !== undefined) {
      return (
        <SGLink
          href={`${url}${path}`}
          aria-label="Link to Study Guide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LaunchIcon />
        </SGLink>
      )
    }
  }

  return (
    <Section key={data.category}>
      <Table showAudio={meta.audio} locale={state.baseLang.code}>
        <thead>
          <tr>
            <th
              className="table-header"
              style={{ color: meta.color }}
              colSpan="3"
            >
              <Trans id={data.category} />
              {renderSGLink(data.path, meta.sgUrl)}
            </th>
          </tr>
          <tr className="table-columns">
            <th>Base</th>
            <th>
              <Trans>Translation</Trans>
            </th>
            {meta.audio ? (
              <th>
                <Trans>Audio</Trans>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>{formatCells(data.phrases)}</tbody>
      </Table>
    </Section>
  )
})

// Styled Components

const Section = styled.section`
  font-size: 16px;
  margin-bottom: 50px;
  border: 1px solid #dcdcdc;
  border-radius: ${radius.default}px;
  padding-bottom: 10px;
  box-shadow: 5px 5px 13px -2px rgba(0, 0, 0, 0.06);

  @media screen and (max-width: 768px) {
    border: none;
    border-top: 1px solid #dcdcdc;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
`

const Table = styled.table`
  margin: 0;

  .table-columns {
    display: none;
  }

  tbody tr td:first-of-type {
    font-weight: bold;
    padding-left: 20px;
    ${({ showAudio }) => (showAudio ? `width: 42%;` : `width: 50%;`)}
    ${({ locale }) => (locale === "zh" ? `font-size: 20px;` : ``)}
  }

  tbody:before {
    content: "";
    display: block;
    padding: 5px;
  }

  .table-header {
    z-index: 1;
    box-shadow: 1px 1px 1px #dadada;
    font-family: ${fonts.heading};
    background: #f9f9f9;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    position: sticky;
    top: 0;

    @media (min-width: ${breakpoints.tablet}px) {
      position: relative;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.95rem;

    .table-header {
      text-align: left;
      font-size: 25px;
    }
  }
`

const Characters = styled.div`
  margin-bottom: 5px;
  font-size: ${({ cyrillic }) => (cyrillic ? `18px` : `21px`)};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: ${({ cyrillic }) => (cyrillic ? `20px` : `25px`)};
  }
`

const Romanization = styled.div`
  font-size: 15px;
  color: #717171;
`

const SGLink = styled.a`
  position: absolute;
  right: 25px;

  svg {
    height: 24px;
    fill: #dcdcdc;
    transition: fill ease 0.3s;
  }

  &:hover {
    svg {
      fill: #848484;
    }
  }
`

const TDShift = styled(a.td)`
  will-change: transform;
`

const AudioDiv = styled(a.div)`
  will-change: opacity;
`

LangTable.propTypes = {
  data: PropTypes.object,
  meta: PropTypes.object,
  audio: PropTypes.bool,
}

LangTable.defaultProps = {
  data: {},
  meta: {},
  audio: true,
}

export default LangTable
